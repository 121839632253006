import React from 'react';

const RecaptchaDisclosure = () => (
  <p style={{ marginTop: '1.1rem' }} className="t-size-xs has-text-gray">
    This site is protected by reCAPTCHA and the Google{' '}
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
  </p>
);

export default RecaptchaDisclosure;
