import React from 'react';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';

import TextInput from '../../form/components/TextInput';
import Submit from '../../form/components/Submit';

const formatDefaultValues = (initialValues) => {
  const formatted = {};

  Object.keys(initialValues).forEach((key) => {
    formatted[key] = initialValues[key].value;
  });

  return formatted;
};

const MainForm = ({
  initialValues,
  gaLabel,
  description,
  errorMessage,
  isLoading,
  display: { showPromo },
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    errors: validationErrors,
  } = useForm({
    defaultValues: formatDefaultValues(initialValues),
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="c-sign-up__main-form l-pos-rel has-padding"
      data-testid="main-form"
      noValidate
    >
      {isLoading && (
        <div className="c-sign-up__loader" data-testid="main-form-loader" />
      )}
      {description && (
        <p
          className="t-lh-b t-size-m has-b-btm-marg"
          aria-hidden="true"
          data-testid="main-form-desc"
        >
          <strong>{description}</strong>
        </p>
      )}
      <div
        className={`c-mini-form ${
          showPromo ? 'has-l-btm-marg' : 'has-xs-btm-marg'
        }`}
      >
        <div className="c-mini-form__input">
          <TextInput
            register={register}
            validation={{
              required: 'Error: Email is required.',
              validate: (value) =>
                isEmail(value.trim()) ||
                'Error: Enter a valid email to sign up.',
            }}
            errors={validationErrors.email}
            showLabel={false}
            label={{ text: 'signup email' }}
            placeholder="Your email address"
            name="email"
            testId="main-form-email"
          />
        </div>
        <div className="c-mini-form__submit">
          <Submit value="Sign up" testId="main-form-submit" />
        </div>
      </div>
      <div className="c-sign-up__phone">
        <TextInput
          register={register}
          validation={{ required: false }}
          errors={validationErrors.phone}
          label={{ text: 'phone' }}
          name="phone"
        />
      </div>
      {!errorMessage && showPromo && (
        <p className="t-size-xs">
          Browse all newsletters at{' '}
          <a
            href="/newsletters/"
            ga-on="click"
            ga-event-category="subscribe intent"
            ga-event-action="signup module link"
            ga-event-label={gaLabel}
          >
            texastribune.org/newsletters
          </a>
          .
        </p>
      )}
      {errorMessage && (
        <p className="c-sign-up__error has-text-error t-size-xxs" data-testid="main-form-error">
          {errorMessage}
        </p>
      )}
    </form>
  );
};

export default MainForm;
