const formatNewsletters = data =>
  data.map(
    ({
      mailchimp_interest_id: interestId,
      logo,
      description,
      name: title,
    }) => ({
      interestId,
      logoUrl: logo,
      description,
      title,
    })
  );

export default formatNewsletters;
