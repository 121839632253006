import React, { useState } from 'react';

import SecondaryForm from '../components/SecondaryForm';
import ajaxer from '../../ajax';
import getRecaptchaToken from '../../utils/getRecaptchaToken';
import { SUBSCRIBE_API_URL, SECONDARY_INTEREST_ID } from '../constants';
import { RECAPTCHA_ACTION_SIGNUP } from '../../constants';

const SecondaryFormContainer = ({
  payload,
  interestId,
  mainSuccessMessage,
  skipSecondaryForm = false,
}) => {
  const [didError, setDidError] = useState(false);
  const [didSucceed, setDidSucceed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function sendToGtm() {
    window.dataLayer.push({
      event: 'customSubscribeSuccess',
      gaAction: 'breaking news alerts',
      gaLabel: 'recommendation',
    });
  }

  async function doSecondarySubscribe() {
    setIsLoading(true);

    // recaptcha tokens are only valid for 2 min, so even though
    // we generated one in the prior form submission we need to regenerate
    let token;
    try {
      token = await getRecaptchaToken(RECAPTCHA_ACTION_SIGNUP);
    } catch (err) {
      setIsLoading(false);
      setDidError(true);
      return;
    }

    try {
      await ajaxer.post(SUBSCRIBE_API_URL, {
        ...payload,
        interests: { [SECONDARY_INTEREST_ID]: true },
        recaptcha_token: token,
        recaptcha_action: RECAPTCHA_ACTION_SIGNUP,
      });
      setDidError(false);
      setDidSucceed(true);
      sendToGtm();
    } catch (err) {
      setDidError(true);
      setDidSucceed(false);
    } finally {
      setIsLoading(false);
    }
  }

  const showForm = SECONDARY_INTEREST_ID !== interestId;

  return (
    <>
      {!skipSecondaryForm && (
        <SecondaryForm
          didError={didError}
          didSucceed={didSucceed}
          isLoading={isLoading}
          mainSuccessMessage={mainSuccessMessage}
          showForm={showForm}
          onSubmit={doSecondarySubscribe}
        />
      )}
      {skipSecondaryForm && (
        <p style={{ alignContent: 'center' }}>
          <strong>{mainSuccessMessage}</strong>
        </p>
      )}
    </>
  );
};

export default SecondaryFormContainer;
