import React from 'react';
import { render } from 'react-dom';

import withDataFetch from '../api-components/hoc/withDataFetch';
import formatQueryParams from '../format-query-params';
import select from '../utils/select';
import ajaxer from '../ajax';
import getRandomNum from '../utils/get-random-num';
import formatNewsletters from './utils/format-newsletters';
import SignUp from './SignUp';
import { NEWSLETTERS_API_URL, SELECTOR } from './constants';
import { __TEST__ } from '../constants';

/*
  This module is exposed in the main, story and front-page bundles.
  Django template: shadow_layouts/js_components/signup.html
*/

function buildFetcher(fallbackData) {
  const { authors = [], gutenTags = [], series = [] } = window.ttGlobal;
  const params = formatQueryParams([
    {
      name: 'author',
      items: authors.map(author => ({ include: true, value: author })),
    },
    {
      name: 'tag',
      items: gutenTags.map(tag => ({ include: true, value: tag })),
    },
    {
      name: 'series',
      items: series.map(seriesItem => ({ include: true, value: seriesItem })),
    },
  ]);

  return async () => {
    try {
      const {
        data: { results },
      } = await ajaxer.get(`${NEWSLETTERS_API_URL}?${params}`);

      if (!results.length) {
        throw new Error();
      }
      const formatted = formatNewsletters(results);
      const num = getRandomNum(results.length);
      return [formatted[num]];
    } catch (err) {
      return fallbackData;
    }
  };
}

export default function buildSignUp({
  selectorSuffix,
  logoUrl,
  isAuto = false,
  interestId,
  title,
  description,
  gaLabel,
  display,
}) {
  const data = [{ interestId, logoUrl, description, title }];
  const displayProps = { isVertical: false, headingLevel: 2, ...display };
  const targetEl = select(`#${SELECTOR}-${selectorSuffix}`);

  if (isAuto) {
    const SignUpWithFetch = withDataFetch(SignUp, {
      fetcher: buildFetcher(data),
      scroll: { element: targetEl },
      showLoader: true,
    });
    const ToRender = (
      <SignUpWithFetch gaLabel={gaLabel} display={displayProps} />
    );
    if (!__TEST__) return render(ToRender, targetEl);
    return ToRender;
  }

  const ToRender = (
    <SignUp dataFetch={{ data }} gaLabel={gaLabel} display={displayProps} />
  );
  if (!__TEST__) return render(ToRender, targetEl);
  return ToRender;
}
