import React from 'react';

interface IHeadingTagProps {
  children: React.ReactNode;
  level: number;
  id?: string;
  display?: {
    cssClasses?: string[];
    srOnly?: boolean;
  };
}

const HeadingTag: React.FC<IHeadingTagProps> = ({
  children,
  level,
  id,
  display: { cssClasses = [], srOnly = false } = {
    cssClasses: [],
    srOnly: false,
  },
}) => {
  const classes = cssClasses;
  const validLevel = level > 6 || level < 1 ? 2 : level;
  const Tag = `h${validLevel}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  if (srOnly) {
    classes.push('is-sr-only');
  }

  return (
    <Tag className={classes.join(' ')} id={id || undefined}>
      {children}
    </Tag>
  );
};

export default HeadingTag;
