/*
  Classes to eventually remove in this file:
  - grid_separator--xs
  - grid_separator--xxxs
  - c-sign-up__input
  - c-sign-up__error
*/

import React from 'react';

import getRandomNum from '../../utils/get-random-num';

const TextInput = ({
  register,
  validation,
  errors,
  name,
  label: { text: labelText, render: renderLabel },
  showLabel = true,
  placeholder = null,
  testId = null,
}) => {
  const uniqueSuffix = getRandomNum(1000);
  const inputClasses = [
    'c-sign-up__input',
    'c-text-input__input',
    'l-display-block',
    'l-width-full',
    'has-text-gray-dark',
    't-size-xs',
    't-lh-b',
  ];

  if (errors) {
    inputClasses.push('is-invalid');
    inputClasses.push('grid_separator--xs');
    inputClasses.push('has-xxxs-btm-marg');
  }

  const renderedLabel = renderLabel ? renderLabel() : labelText;

  return (
    <div className="c-text-input">
      {showLabel && (
        <label
          htmlFor={`${name}-${uniqueSuffix}`}
          className="l-display-block has-xxxs-btm-marg grid_separator--xxxs t-size-s"
        >
          <strong>{renderedLabel}</strong>
        </label>
      )}
      <input
        ref={register(validation)}
        id={`${name}-${uniqueSuffix}`}
        name={name}
        placeholder={placeholder}
        aria-label={showLabel ? false : renderedLabel}
        aria-invalid={!!errors}
        aria-describedby={`${name}-errors-${uniqueSuffix}`}
        data-testid={testId}
        className={inputClasses.join(' ')}
        type="text"
      />
      <ul
        id={`${name}-errors-${uniqueSuffix}`}
        aria-live="polite"
        data-testid={`${testId}-errors`}
        className="c-sign-up__error has-text-error t-size-xxs"
      >
        {errors && <li>{errors.message}</li>}
      </ul>
    </div>
  );
};

export default TextInput;
