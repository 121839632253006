/*
  Classes to eventually remove in this file:
  - button
  - button--yellow
  - c-sign-up__submit
*/

import React from 'react';

const Submit = ({
  value = 'Submit',
  display: { bgColor } = { bgColor: 'yellow' },
  testId = null,
}) => {
  let textColor;

  if (bgColor === 'yellow') {
    textColor = 'black-off';
  } else if (bgColor === 'teal') {
    textColor = 'white';
  }

  return (
    <input
      value={value}
      data-testid={testId}
      aria-label={value}
      type="submit"
      className={`c-button c-button--s c-sign-up__submit button button--yellow has-text-${textColor} has-bg-${bgColor} l-width-full l-display-block`}
    />
  );
};

export default Submit;
