import { EApiFilter } from './types';

interface ParamMeta {
  name: EApiFilter;
  items: {
    include: boolean;
    value: number | string;
  }[];
}

export default function formatQueryParams(
  params: ParamMeta[],
  fields: string[] = []
): string {
  let combined = '';

  if (fields.length) {
    combined += `fields=${fields.join(',')}`;

    if (params.length) {
      combined += '&';
    }
  }

  params.forEach(({ name, items }, paramsIdx) => {
    items.forEach(({ value, include }, itemsIdx) => {
      combined += `${name}${include ? '=' : '!='}${value}`;

      const shouldAddAmpersand =
        paramsIdx !== params.length - 1 || itemsIdx !== items.length - 1;

      if (shouldAddAmpersand) {
        combined += '&';
      }
    });
  });

  return combined;
}
