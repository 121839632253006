import React, { useState } from 'react';

import MainForm from './containers/MainForm';
import SecondaryForm from './containers/SecondaryForm';
import createUtmString from '../utils/create-utm-string';
import HeadingTag from '../display/components/HeadingTag';

const SignUp = ({
  dataFetch,
  gaLabel,
  display: {
    bgColor,
    isVertical,
    headingLevel = 2,
    skipSecondaryForm = false,
    hideImage = false,
  },
}) => {
  const { interestId, logoUrl, title, description } = dataFetch.data[0];
  const [payload, setPayload] = useState({
    ga_label: gaLabel,
    utm_code: createUtmString(),
  });
  const [successMessage, updateSuccessMessage] = useState('');

  const updatePayload = (newPayload) => {
    setPayload((oldPayload) => ({
      ...oldPayload,
      ...newPayload,
    }));
  };

  const formsClasses = ['c-sign-up__forms'];
  const signUpClasses = ['c-sign-up', 't-links-underlined'];
  const didShift = !!payload.email;

  if (isVertical) {
    signUpClasses.push('c-sign-up--vert');
  } else {
    signUpClasses.push('c-sign-up--horiz');
  }

  if (didShift) {
    formsClasses.push('did-shift');
  }

  return (
    <section className={`${signUpClasses.join(' ')}`} data-testid="sign-up">
      {description && (
        <HeadingTag level={headingLevel} display={{ srOnly: true }}>
          {description}
        </HeadingTag>
      )}
      {logoUrl && !hideImage && (
        <div
          className="c-sign-up__logo"
          style={{ backgroundColor: bgColor }}
          data-testid="logo"
        >
          <img
            src={logoUrl}
            alt={`${title} newsletter`}
            className="l-align-center-x"
          />
        </div>
      )}
      <div className="c-sign-up__content">
        <div className={`${formsClasses.join(' ')}`} data-testid="carousel">
          <MainForm
            title={title}
            description={description}
            interestId={interestId}
            payload={payload}
            gaLabel={gaLabel}
            showPromo={!isVertical}
            updateSuccessMessage={updateSuccessMessage}
            updatePayload={updatePayload}
          />
          <SecondaryForm
            title={title}
            payload={payload}
            interestId={interestId}
            mainSuccessMessage={successMessage}
            skipSecondaryForm={skipSecondaryForm}
          />
        </div>
      </div>
    </section>
  );
};

export default SignUp;
