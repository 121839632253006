import React from 'react';
import RecaptchaDisclosure from './RecaptchaDisclosure';

import Submit from '../../form/components/Submit';

const SecondaryForm = ({
  didError,
  didSucceed,
  isLoading,
  mainSuccessMessage,
  showForm,
  onSubmit,
}) => {
  const noSubmission = !didError && !didSucceed;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      className="c-sign-up__secondary-form l-pos-rel has-padding"
      aria-live="polite"
      aria-atomic="true"
      data-testid="secondary-form"
      noValidate
    >
      {isLoading && (
        <div
          className="c-sign-up__loader"
          data-testid="secondary-form-loader"
        />
      )}
      {noSubmission && (
        <p className="t-lh-b t-size-m has-b-btm-marg">
          <strong>{mainSuccessMessage}</strong>
        </p>
      )}
      {didSucceed && (
        <p className="t-lh-b t-size-m has-b-btm-marg">
          <strong>Thanks! You&apos;re all set.</strong>
        </p>
      )}
      {didError && (
        <p className="t-lh-b t-size-m has-b-btm-marg">
          <strong>
            We&apos;re sorry. Your newsletter sign-up didn&apos;t go through.
          </strong>
        </p>
      )}
      {noSubmission && showForm && (
        <p className="t-size-s has-text-gray-dark has-b-btm-marg">
          While we&apos;re here &#8230; would you like to get emails about the
          latest breaking news?
        </p>
      )}
      {didError && (
        <p
          className="t-size-s has-text-error has-b-btm-marg"
          data-testid="secondary-form-error"
        >
          This may be because of a slow or interrupted connection. Please try
          again and, if the issue persists, contact us at
          community@texastribune.org.
        </p>
      )}
      {!didSucceed && showForm && (
        <>
          <Submit
            value="Sign up for breaking news"
            testId="secondary-form-submit"
          />
          <RecaptchaDisclosure />
        </>
      )}
    </form>
  );
};

export default SecondaryForm;
