import React from 'react';

const Loader = ({ display: { suffix } = { suffix: 's' } }) => (
  <div
    className={`c-loading c-loading--${suffix} loading loading--${suffix}`}
    aria-hidden="true"
  />
);

export default Loader;
