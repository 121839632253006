import React, { Component } from 'react';

import Loader from '../../display/components/Loader';
import bindScrollActions from '../../utils/scroll-actions';

/**
 * Wraps a React component with a fetching mechanism.
 *
 * @param {React.Component} WrappedComponent - component to wrap
 * @param {Object} options - options for fetching
 * @param {Function} options.fetcher - function to fetch data
 * @param {Object} options.scroll - trigger fetching based on intersection
 * @param {Boolean} options.showLoader - whether to show loader on mount
 * @param {Boolean} options.logErrors - whether to log fetch errors
 *
 * @returns {React.Component} - component with fetching functionality
 */
export default function withDataFetch(
  WrappedComponent,
  { fetcher, scroll, showLoader = false, logErrors = false }
) {
  class Wrapper extends Component {
    constructor() {
      super();

      this.state = {
        data: [],
        isPending: true,
        didError: false,
      };

      this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
      if (scroll) {
        this.buildObserver();
      } else {
        this.fetchData();
      }
    }

    buildObserver() {
      const { element, observerOptions = {} } = scroll;
      const callback = () => {
        this.fetchData();
      };

      bindScrollActions({
        callback,
        elements: [element],
        doOnce: true,
        observerOptions,
      });
    }

    async fetchData() {
      try {
        const data = await fetcher();
        this.setState({ data, isPending: false, didError: false });
      } catch (err) {
        if (logErrors) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
        this.setState({ isPending: false, didError: true });
      }
    }

    render() {
      const { data, isPending, didError } = this.state;

      if (didError || (!isPending && !data.length)) return null;
      if (showLoader && isPending) return <Loader />;

      return (
        <WrappedComponent dataFetch={{ data, isPending }} {...this.props} />
      );
    }
  }

  return Wrapper;
}
