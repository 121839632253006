import React, { useState } from 'react';

import ajaxer from '../../ajax';
import MainForm from '../components/MainForm';
import { GENERIC_ERROR_MSG, SUBSCRIBE_API_URL } from '../constants';
import { RECAPTCHA_ACTION_SIGNUP } from '../../constants';
import getRecaptchaToken from '../../utils/getRecaptchaToken';

const MainFormContainer = ({
  interestId,
  title,
  description,
  payload,
  gaLabel,
  showPromo,
  updatePayload,
  updateSuccessMessage,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function sendToGtm() {
    window.dataLayer.push({
      event: 'customSubscribeSuccess',
      gaAction: title.toLowerCase(),
      gaLabel,
    });
  }

  async function doSubcribe({ email, phone }) {
    setIsLoading(true);
    // get recaptcha token
    let token;
    try {
      token = await getRecaptchaToken(RECAPTCHA_ACTION_SIGNUP);
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(GENERIC_ERROR_MSG);
      return;
    }

    const payloadUpdates = {
      honeypot: phone,
      email: email.trim(),
      recaptcha_token: token,
      recaptcha_action: RECAPTCHA_ACTION_SIGNUP,
    };

    try {
      const response = await ajaxer.post(SUBSCRIBE_API_URL, {
        ...payload,
        ...payloadUpdates,
        interests: { [interestId]: true },
      });

      updatePayload(payloadUpdates);
      sendToGtm();

      if (response && response.data && response.data.detail) {
        updateSuccessMessage(response.data.detail);
      } else {
        updateSuccessMessage("You've been successfully subscribed!");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage(GENERIC_ERROR_MSG);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <MainForm
      description={description}
      gaLabel={gaLabel}
      initialValues={{
        email: {
          value: '',
        },
        phone: {
          value: '',
        },
      }}
      isLoading={isLoading}
      errorMessage={errorMessage}
      display={{ showPromo }}
      onSubmit={doSubcribe}
    />
  );
};

export default MainFormContainer;
